import React from "react";
import { ReactComponent as ReactIcon } from "./resources/images/react.svg";
import { ReactComponent as Node } from "./resources/images/node.svg";
import { ReactComponent as JS } from "./resources/images/javascript-original.svg";
import { ReactComponent as Mongo } from "./resources/images/mongodb.svg";
import { ReactComponent as Webpack } from "./resources/images/webpack.svg";
import { ReactComponent as Jest } from "./resources/images/jest.svg";
import { ReactComponent as Redux } from "./resources/images/redux.svg";
import { ReactComponent as Sass } from "./resources/images/sass.svg";
import { ReactComponent as BigBinary } from "./resources/images/BigBinary.svg";
import garagetroop from "./resources/images/garagetroop.png";

import "./data.css";

const firstPage = (
  <div className="first-page">
    <p>
      I'm a Software Engineer & avid open source contributor, with a knack for
      performance and UX. Currently working at{" "}
      <a href="https://www.bigbinary.com/">BigBinary</a>.
    </p>
    <div className="icons-grid">
      <JS />
      <ReactIcon />
      <Node />
      <Mongo />
      <Webpack />
      <Redux />
      <Jest />
      <Sass />
    </div>
  </div>
);

const projectsInfo = (
  <div>
    <h4>Some of my projects:</h4>
    <ul className="centered-projects">
      <li>
        <a href="https://gitlab.com/sapoliakaran/chat-app">
          IRC Chat Application
        </a>
      </li>
      <li>
        <a href="https://www.npmjs.com/package/v8codecov">
          v8codecov - Code Coverage analyzer utility
        </a>
      </li>
    </ul>
  </div>
);

const experienceInfo = (
  <div className="experience-info">
    <h4>
      <ul>
        <li className="experience-info-li">
          <a href="https://www.bigbinary.com/">
            <i className="status-icon"></i>
            <BigBinary />
          </a>
          Software Engineer
          <p>June 2020</p>
        </li>
        <li>
          <a href="https://garagetroop.com/">
            <img src={garagetroop} alt="garagetroop logo" />
          </a>
          Co-founder & Tech Lead
          <p>Feb 2017</p>
        </li>
      </ul>
    </h4>
  </div>
);

const contactInfo = (
  <h5>
    Contact me at: @karansapolia on{" "}
    <a href="https://twitter.com/karansapolia">Twitter</a> and{" "}
    <a href="https://github.com/karansapolia">Github</a>. Or at my email:
    sapoliakaran (at)(the)(rate) gmail
  </h5>
);

export default [
  {
    section1: {
      type: "component",
      src: (
        <a
          className="twitter-timeline"
          data-width="500"
          data-height="300"
          data-theme="light"
          href="https://twitter.com/karansapolia?ref_src=twsrc%5Etfw"
        >
          Tweets by karansapolia
        </a>
      ),
    },
    classNames: "contact-card",
    name: "Contact me",
    text: contactInfo,
  },
  {
    section1: {
      type: null,
    },
    name: "Experience",
    text: experienceInfo,
  },
  {
    section1: {
      type: "pic",
      src:
        "https://images.unsplash.com/photo-1509966756634-9c23dd6e6815?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1010&q=80",
      alt: "IDE open on a laptop",
    },
    name: "Projects",
    text: projectsInfo,
  },
  {
    section1: {
      type: "pic",
      src: "https://live.staticflickr.com/65535/49945859848_14c70b37e0_z.jpg",
      alt: "Headshot of Karan Sapolia smiling at the camera",
    },
    name: "Karan Sapolia",
    age: 23,
    distance: "Available Remote",
    text: firstPage,
  },
];
