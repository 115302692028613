/** @jsx jsx */
import { jsx } from "theme-ui";
import { lighten } from "@theme-ui/color";
import { Link } from "gatsby";
// import { lighten } from "polished";

const BlogBanner = ({ post }) => {
  return (
    <Link
      to={post.slug}
      sx={{
        textDecoration: "none",
        color: (theme) => theme.colors.bodyColor,
      }}
    >
      <div
        className="article-hero"
        sx={{
          backgroundColor: (theme) => lighten(theme.colors.bodyBg, 0.15),
        }}
      >
        <h2>{post.title}</h2>
        <p>{post.excerpt}</p>
      </div>
    </Link>
  );
};

export default BlogBanner;
