/** @jsx jsx */
import { jsx } from "theme-ui";
import { lighten } from "@theme-ui/color";
import { useState } from "react";
import { useSprings, animated, interpolate } from "react-spring";
import { useGesture } from "react-use-gesture";

import "./Deck.css";
import data from "../../data.js";

const Deck = () => {
  // These two are just helpers, they curate spring data, values that are later being interpolated into css
  const to = i => ({
    x: 0,
    y: i * -4,
    scale: 1,
    rot: -10 + Math.random() * 20,
    delay: i * 100,
  });
  const from = i => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });

  // This is being used down there in the view, it interpolates rotation and scale into a css transform
  const trans = (r, s) =>
    `perspective(1500px) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`;

  const [gone] = useState(() => new Set()); // The set flags all the cards that are flicked out
  const [props, set] = useSprings(data.length, i => ({
    ...to(i),
    from: from(i),
  })); // Create a bunch of springs using the helpers above
  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const bind = useGesture(
    ({
      args: [index],
      down,
      delta: [xDelta],
      distance,
      direction: [xDir],
      velocity,
    }) => {
      const trigger = velocity > 0.2; // If you flick hard enough it should trigger the card to fly out
      const dir = xDir < 0 ? -1 : 1; // Direction should either point left or right
      if (!down && trigger) gone.add(index); // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
      set(i => {
        if (index !== i) return; // We're only interested in changing spring-data for the current spring
        const isGone = gone.has(index);
        const x = isGone ? (200 + window.innerWidth) * dir : down ? xDelta : 0; // When a card is gone it flys out left or right, otherwise goes back to zero
        const rot = xDelta / 100 + (isGone ? dir * 10 * velocity : 0); // How much the card tilts, flicking it harder makes it rotate faster
        const scale = down ? 1 : 1; // Active cards lift up a bit
        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
        };
      });
      if (!down && gone.size === data.length)
        setTimeout(() => gone.clear() || set(i => to(i)), 600);
    }
  );

  const renderSection1 = data => {
    const decisionhash = {
      pic: {
        value: (
          <div className="section1">
            <img
              src={data.src}
              key={data.src}
              loading="lazy"
              alt="section"
              className="cover-image"
            />
          </div>
        ),
      },
      component: {
        value: <div className="section1">{data.src}</div>,
      },
      null: {
        value: null,
      },
    };

    return decisionhash[data.type].value;
  };

  // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
  return (
    <div className="deck-container">
      {props.map(({ x, y, rot, scale }, i) => (
        <animated.div
          className="xyDisplacement"
          key={i}
          style={{
            transform: interpolate(
              [x, y],
              (x, y) => `translate3d(${x}px,${y}px,0)`
            ),
          }}
        >
          {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
          <animated.div
            {...bind(i)}
            className="xyRotation"
            style={{
              transform: interpolate([rot, scale], trans),
            }}
          >
            <div
              className={data[i].classNames ? data[i].classNames : "card"}
              sx={{
                backgroundColor: theme => lighten(theme.colors.bodyBg, 0.15),
              }}
            >
              {renderSection1(data[i].section1)}
              <section className="card-description">
                <div className="card-title-block">
                  <h2>{data[i].name}</h2>
                  <div className="subtext-block">
                    {data[i].distance === "Available Remote" ? (
                      <i
                        className="status-icon"
                        alt="status icon indicating availability"
                      ></i>
                    ) : null}
                    <p className="subtext">{data[i].distance}</p>
                  </div>
                </div>
                <div className="description">{data[i].text}</div>
                {data[i].name === "Contact me" ? null : (
                  <h5 className="swipe-text">Swipe to learn more -></h5>
                )}
              </section>
            </div>
          </animated.div>
        </animated.div>
      ))}
    </div>
  );
};

export default Deck;
