/** @jsx jsx */
import { jsx } from "theme-ui";
import { IconContext } from "react-icons";
import { FiMoon, FiSun } from "react-icons/fi";

import "./LightSwitch.css";
import { useColorMode } from "theme-ui";

const LightSwitch = () => {
  const [colorMode, setColorMode] = useColorMode();

  const iconCSS = {
    display: "flex",
    alignItems: "center",
    justifyContet: "center",
    margin: "0",
    padding: "0",
  };

  return (
    <div
      id="light-switch"
      className={colorMode === "default" ? "light" : "dark"}
      sx={{ color: (theme) => theme.colors.bodyColor }}
      onClick={() => setColorMode(colorMode === "default" ? "dark" : "default")}
      id="light-switch"
      aria-label="Switch Theme"
      title="Switch Theme"
      alt={
        colorMode === "dark" ? "switch to light mode" : "switch to dark mode"
      }
    >
      <IconContext.Provider value={{ size: "1.4em" }}>
        {colorMode === "dark" ? (
          <FiSun style={iconCSS} />
        ) : (
          <FiMoon style={iconCSS} />
        )}
      </IconContext.Provider>
    </div>
  );
};

export default LightSwitch;
