import React from "react";
import { Global } from "@emotion/core";
import Appsignal from "@appsignal/javascript";
import { ErrorBoundary } from "@appsignal/react";

import Deck from "../Deck";
import LightSwitch from "../LightSwitch";
import Blog from "../Blog";

import "./App.css";

const appsignal = new Appsignal({
  key: "21383716-7e35-4c40-892a-24b0ed338130",
});

const FallbackComponent = () => <div>Uh oh! There was an error :(</div>;

const App = () => {
  return (
    <ErrorBoundary
      instance={appsignal}
      tags={{ tag: "value" }}
      fallback={error => <FallbackComponent />}
    >
      <Global
        styles={theme => ({
          body: {
            color: theme.colors.bodyColor,
            backgroundColor: theme.colors.bodyBg,
            transition: `background-color 500ms`,
          },
        })}
      />
      <div className="scroll-section"></div>
      <LightSwitch />
      <Deck />
      <Blog />
    </ErrorBoundary>
  );
};

export default App;
