/** @jsx jsx */
import { jsx } from "theme-ui";
import BlogBanner from "../BlogBanner";

import usePosts from "../../hooks/use-posts";
import external_posts from "../../external_posts";

import "./blog.css";

const Blog = () => {
  const posts = usePosts();

  // const heroCSS = {
  //   backgroundColor: lighten(0.15, theme.colors.bodyBg),
  //   // box border
  //   borderRadius: "10px",
  //   border: `1px solid ${theme.colors.bodyColor}`,
  // };

  // console.log({ heroCSS });

  let bottomGradient = theme => `${theme.colors.bodyBg}99`;

  // const blogTitleCSS = {
  //   backgroundImage: `linear-gradient(
  //     ${theme.colors.bodyBg} 20%,
  //     ${bottomGradient}
  //   )`,
  // };

  return (
    <div className="blog scroll-section">
      <div
        className="blog-title"
        sx={{
          backgroundImage: theme => `linear-gradient(
      ${theme.colors.bodyBg} 20%,
      ${bottomGradient}
    )`,
        }}
      >
        <div className="title-container">
          <h1 id="openquote">"</h1>
          <h1
            id="blog-title"
            sx={{
              backgroundImage: theme => `linear-gradient(
                135deg,
                ${theme.colors.blogTitle} 65%,
                ${theme.colors.bodyBg} 100%
              )`,
            }}
          >
            Karan's Notes
          </h1>
          <h1 id="closequote">"</h1>
        </div>
      </div>
      <div className="articles">
        {external_posts.map(post => (
          <BlogBanner key={post.title} post={post} />
        ))}
        {posts.map(post => (
          <BlogBanner key={post.slug} post={post} />
        ))}
      </div>
    </div>
  );
};

export default Blog;
